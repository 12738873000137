import CreateBtn from './create_btn';
import CreateContent from './create_content';
import { checkElements, isElement } from '../helpers/check_elements';

const Animation = (content, height) => () => {
  if (content.style.height === '0px') {
    content.style.height = `${height + 10}px`;
    content.setAttribute('aria-hidden', 'false');
  } else {
    content.style.height = '0px';
    content.setAttribute('aria-hidden', 'true');
  }
};

const clickHandler = (btn, animator) => (e) => {
  e.preventDefault();
  const pressed = btn.getAttribute('aria-pressed') === 'false';
  btn.setAttribute('aria-pressed', pressed.toString());

  animator();
};

const checkForm = (el) => {
  const form = el.closest('form');
  if (!form) return;

  form.addEventListener('keypress', (e) => {
    if (e.key === 'Enter') {
      el.disabled = true;
      form.submit();
    }
  });
};

const createElements = (el, expanders, contents) => ({
  expandedBtn: CreateBtn({ el, expanders }),
  expandedContent: CreateContent({ el, contents }),
});

const getElements = (el) => ({
  contents: [...el.querySelectorAll('[data-expanding-content]')],
  expanders: [...el.querySelectorAll('[data-expander]')],
});

const checker = (contents, expanders) =>
  checkElements(...contents) && checkElements(...expanders);

export default (el) => {
  if (!isElement(el)) return;
  const { contents, expanders } = getElements(el);

  const open = el.dataset.expandedDefault === 'true';

  if (!checker(contents, expanders)) return;

  const { expandedBtn, expandedContent } = createElements(
    el,
    expanders,
    contents
  );

  if (!expandedContent || !expandedBtn) return;

  const sizes = expandedContent.getBoundingClientRect();

  const animator = Animation(expandedContent, sizes.height + 10);
  expandedContent.style.height = open ? `${sizes.height + 10}px` : 0;

  el.dataset.expandingSet = !open;

  checkForm(expandedBtn);
  expandedBtn.addEventListener('click', clickHandler(expandedBtn, animator));
};
