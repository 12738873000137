import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="google-maps"
export default class extends Controller {
  static values = {
    pins: Array
  }

 connect() {
  if (typeof google === 'undefined') {
    window.initMap = () => { 
      this.initializeMap(); 
    }
  } else {
    this.initializeMap();
  }
 } 

 initializeMap() {
    this.map = new google.maps.Map(this.element, {
      zoom: 15, // Adjust as needed
    });

    this.addMarkers();
  }

  addMarkers() {
    const bounds = new google.maps.LatLngBounds(); 
    this.pinsValue.forEach(pin => {
      const position = new google.maps.LatLng(pin.lat, pin.lng);
      const marker = new google.maps.Marker({
        position: position,
        map: this.map
      });

      bounds.extend(position);

      const infoWindow = new google.maps.InfoWindow({
        content: pin.content
      });

      marker.addListener("click", () => {
        infoWindow.open(this.map, marker);
      });

      this.map.fitBounds(bounds);
    });
  }
}

