import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  
  static targets = ["closebtn", "container"]

  connect(){  
    const storageType = sessionStorage;
    const consentPropertyName = 'spa_local_accept';
    const shouldShowPopup = () => !storageType.getItem(consentPropertyName);
    const saveToStorage = () => storageType.setItem(consentPropertyName, true);

    const acceptFn = event => {
      console.log(event);
      event.preventDefault;
      saveToStorage(storageType);
      consentPopup.classList.add('hidden');
    }

    const consentPopup = this.containerTarget;
    const acceptBtn = this.closebtnTarget;
    acceptBtn.addEventListener('click', acceptFn);

    if (shouldShowPopup(storageType)) {
      consentPopup.classList.remove('hidden');
    }
  }

  expand

}
