export default () => {
  const banner = document.querySelector('.banner');
  const bannerImage = document.querySelector('.banner-background');
  const bannerOverlay = document.querySelector('.banner-overlay');

  if (!bannerImage) return;

  if (bannerImage) {
    const bannerHeight = bannerOverlay.offsetHeight;

    if (window.innerWidth >= 1024) {
      bannerOverlay.style.bottom = '-' + bannerHeight / 2 + 'px';
      banner.style.marginBottom = bannerHeight / 2 + 20 + 'px';
    } else {
      bannerOverlay.style.bottom = '-' + 105 + 'px';
    }
  }
};
