export default () => {
  var jq = jQuery.noConflict();
  jq(document).ready(function () {
    
    jq('.promo-homepage').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      centerMode: true,
      variableWidth: true,
      mobileFirst: true, 
      responsive: [
        {
          breakpoint: 480,
          settings: "unslick"
        }
      ]
    });
    
    jq('.text-only__carousel').slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      centerMode: true,
      variableWidth: true,
      mobileFirst: true, 
    });

  });
};
