import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="simple-open"
export default class extends Controller {

  static targets = ["target"]

  connect() {
  }

  toggle() {
    this.targetTarget.classList.toggle('open');
  }
}
