import ViewportDetect from '@djforth/viewport-detection-fp';
import Tracker from './tracker';
import SetUpExpander from './setup';

export default () => {
  const elms = [...document.querySelectorAll('[data-expanding-panel]')];
  if (elms.length <= 0) return;

  const vpDetect = ViewportDetect();

  const callback = Tracker(elms);
  callback(vpDetect.getDevice());

  vpDetect.addCallback(callback);
  vpDetect.track();

  window.SetUpExpander = SetUpExpander;
};
