import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="mob-logo"
export default class extends Controller {
  connect() {
    const logo = '<div class="logo"><div class="sfContentBlock"><a href="/">SPA Experience by better</a></div></div>'
    let mmNav = this.element.querySelector('.mm-navbar');
    
    if (mmNav){
      mmNav.insertAdjacentHTML("afterend", logo);
    }

  }
}
