import SetUpExpander from './setup';

const clone = (elms) => elms.map((el) => el.cloneNode(true));

const CheckDevices = (el) => {
  const json = JSON.parse(el.dataset.expandingPanel);
  const devices = Array.isArray(json) ? json : ['mobile'];
  return (device) => devices.includes(device);
};

export default (elms) => {
  let device = '';
  const original = clone(elms);

  return (d) => {
    if (d !== device) {
      elms.forEach((el, i) => {
        const checkDevices = CheckDevices(el);

        const set = el.dataset.expandingSet || 'false';

        if (checkDevices(d) && set === 'false') {
          SetUpExpander(el);
          el.dataset.expandingSet = 'true';
        } else if (!checkDevices(d) && set === 'true') {
          el.innerHTML = original[i].innerHTML;
          el.dataset.expandingSet = 'false';
        }
      });
    }

    device = d;
  };
};
