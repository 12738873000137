import { Controller } from "@hotwired/stimulus"


// Connects to data-controller="dropdown-btn"
export default class extends Controller {
  
  static targets = ["btnspan"]

  toggle(event){
    event.preventDefault();
    // console.log(this.btnspanTarget)
    this.btnspanTarget.closest('.venue-btn').querySelector('.btn-venue-list').classList.toggle('open-venue-list');
  }

}
