import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="alert-expand"
export default class extends Controller {
  connect() {
  }

  toggle(){
    this.element.classList.toggle('open');
  }
}
