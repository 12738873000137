const contentWrapper = (inner, css = '', closed = true) => `
  <div class='expanding_panels__content ${css}' aria-hidden="${closed}">${inner}</div>
`;

export const unwrapContent = (el) => {
  const content = el.querySelector('.expanding_panels__content');
  if (content instanceof Element) {
    const unwrappedContent = content.innerHTML;
    el.removeChild(content);
    el.insertAdjacentHTML('beforeend', unwrappedContent);
  }
};

export default ({ el, contents }) => {
  const innerContents = contents.reduce(
    (conts, content) => conts + content.outerHTML,
    ''
  );

  const open = el.dataset.expandedDefault === 'true';

  const expanderContent = contentWrapper(
    innerContents,
    el.dataset.expandedClass,
    !open
  );

  contents.forEach((content) => {
    el.removeChild(content);
  });
  el.insertAdjacentHTML('beforeend', expanderContent);

  const elm = el.querySelector('.expanding_panels__content');

  return elm;
};
