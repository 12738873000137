// Entry point for the build script in your package.json
import * as bootstrap from "bootstrap"
import 'trix'
import '@rails/actiontext'
import '@rails/activestorage'
import '@rails/ujs';

import './modules';
import "./controllers"
import '@hotwired/turbo-rails'

console.log('application.js has loaded')
